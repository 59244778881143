import React from 'react'
import Courses from '../components/courses'
import Accordion from 'react-bootstrap/Accordion';
import MissingAssignments from './missing-assignments';
import Calendar from './calendar';

export default function Observees({ observees, courses }) {

  if (observees.length === 0 || courses.length === 0) return null

  const ObserveeRow = (observee, key) => {
    return (
      <Accordion.Item eventKey={key}>
        <Accordion.Header>{observee.name}</Accordion.Header>
        <Accordion.Body>
          <Calendar courses={courses} id={observee.key} />
          <MissingAssignments id={observee.key} />
          <Courses courses={courses} id={observee.key} />
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  const observeeTable = observees.map((observee, key) => ObserveeRow(observee, key))

  return (
    <Accordion>
      {observeeTable}
    </Accordion>
  )
}