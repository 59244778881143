import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import getMissingAssignments from '../lib/getMissingAssignments';

export default function MissingAssignments({ id }) {

  const [missingAssignments, setMissingAssignments] = useState([]);

  useEffect(() => {
    if(!missingAssignments || missingAssignments.length === 0) {
      getMissingAssignments(id).then(missingAssignments => {
        if(missingAssignments && missingAssignments.length > 0)
          setMissingAssignments(missingAssignments);
      })
    }
  });

  const MissingAssignmentRow = (missingAssignment, key) => {
    return (
      <tr key={key}>
        <td>{missingAssignment.name}</td>
        <td>{missingAssignment.due}</td>
        <td>{missingAssignment.points_possible}</td>
      </tr>
    )
  }

  const missingAssignmentTable = missingAssignments.map((missingAssignment, key) => MissingAssignmentRow(missingAssignment, key))

  return (
    <>
    <Accordion>
      <Accordion.Header>Missing Assignments</Accordion.Header>
        <Accordion.Body>
          <div>
          <table>
            <thead>
              <td>Name</td>
              <td>Due Date</td>
              <td>Points</td>
            </thead>
            <tbody>
              {missingAssignmentTable}
            </tbody>
          </table>
        </div>
      </Accordion.Body>
    </Accordion>
    </>
  )

}