import React, { useState, useEffect } from 'react';
import getAssignments from '../lib/getAssignments';

export default function Assignments({ id }) {

  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    if(!assignments || assignments.length === 0) {
      getAssignments(id).then(assignments => {
        if(assignments && assignments.length > 0)
          setAssignments(assignments);
      })
    }
  });

  const AssignmentRow = (assignment, key) => {
    return (
      <tr key={key}>
        <td>{assignment.name}</td>
        <td>{assignment.due}</td>
        <td>{assignment.points ?? '-'}/{assignment.points_possible}</td>
        <td>{assignment.submitted}</td>
      </tr>
    )
  }

  const assignmentTable = assignments.map((assignment, key) => AssignmentRow(assignment, key))

  return (
    <>
    <div>
      <table>
        <thead>
          <td>Name</td>
          <td>Due Date</td>
          <td>Score</td>
          <td>Status</td>
        </thead>
        <tbody>
          {assignmentTable}
        </tbody>
      </table>
    </div>
    </>
  )

}