import React, { useState, useEffect } from 'react';
import getObservees from './lib/getObservees';
import getCourses from './lib/getCourses';
import Observees from './components/observees'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [observees, setObservees] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if(!observees || observees.length === 0) {
      getObservees().then(observees => {
        setObservees(observees);
      })
    }
    if(!courses || courses.length === 0) {
      getCourses().then(courses => {
        setCourses(courses);
      })
    }
  });

  return <> 
  {(observees.length === 0 || courses.length === 0) &&
    <div>Loading...</div>
  }
  {(observees.length > 0 && courses.length > 0) &&
    <div>
      <Observees observees={observees} courses={courses} />
    </div>
  }
  </>;
}

export default App;