import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import getCalendar from '../lib/getCalendar';

export default function Calendar({ courses, id }) {

  const [calendar, setCalendar] = useState([]);

  useEffect(() => {
    if(!calendar || calendar.length === 0) {
      getCalendar(courses, id).then(calendar => {
        if(calendar && calendar.length > 0)
        setCalendar(calendar);
      })
    }
  });

  const CalendarRow = (calendar, key) => {
    return (
      <tr key={key}>
        <td>{calendar.name}</td>
        <td>{calendar.due}</td>
        <td>{calendar.points_possible}</td>
        <td>{calendar.submitted}</td>
      </tr>
    )
  }

  const calendarTable = calendar.map((calendar, key) => CalendarRow(calendar, key))

  return (
    <>
    <Accordion>
      <Accordion.Header>Calendar</Accordion.Header>
        <Accordion.Body>
          <div>
          <table>
            <thead>
              <td>Name</td>
              <td>Due Date</td>
              <td>Points</td>
              <td>Status</td>
            </thead>
            <tbody>
              {calendarTable}
            </tbody>
          </table>
        </div>
      </Accordion.Body>
    </Accordion>
    </>
  )

}