export default async function getMissingAssignments(id) {
  const res = await fetch(`/api/missing-assignments?userid=${id}`);
  const assignments = await formatMissingAssignments(await res.json());
  return assignments;
}

function formatMissingAssignments(assignments) {
  assignments.sort(function(a, b) {
    return new Date(a.due_at) - new Date(b.due_at);
  });

  var list = assignments.map(item => {
    const date = new Date(item.due_at);
    var newdate = date.getFullYear() === 1969 ? "No Date" : (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();

    const assignment = {};
    assignment.key = item.id;
    assignment.name = item.name;
    assignment.due = newdate;
    assignment.points_possible = item.points_possible;
    return assignment;
  });
  return list;
}
