export default async function getAssignments(courseid) {
  const res = await fetch(`/api/assignments?courseid=${courseid}`);
  const assignments = await formatAssignments(await res.json());
  return assignments;
}

function formatAssignments(assignments) {
  if (!Array.isArray(assignments) || assignments.length === 0) return null

  var assignmentList = [];
  for(var i = 0; i < assignments.length; i++) {
    assignments[i].assignments.sort(function(a, b) {
      return new Date(a.due_at) - new Date(b.due_at);
    });
    var list = assignments[i].assignments.map(item => {
      const date = new Date(item.due_at);
      var newdate = date.getFullYear() === 1969 ? "No Date" : (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
  
      const assignment = {};
      assignment.key = item.id;
      assignment.name = item.name;
      assignment.points = item.submission[0].score
      assignment.points_possible = item.points_possible;
      assignment.due = newdate;
      assignment.submitted = item.submission[0].workflow_state;
      return assignment;
    });
    assignmentList.push(...list);
  }
  return assignmentList;
}
