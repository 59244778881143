export default async function getCalendar(courses, id) {

  courses = courses.filter(course => course.enrollmentid === id);
  var courseList = "";
  for(var i = 0; i < courses.length; i++) {
    courseList += "&context_codes%5B%5D=course_" + courses[i].key;
  }

  const res = await fetch(`/api/calendar?userid=${id}&courses=${escape(courseList)}`);
  const calendar = await formatCalendar(await res.json());
  return calendar;

}

function formatCalendar(calendar) {
  if (!Array.isArray(calendar) || calendar.length === 0) return null

  var list = calendar.map(item => {
    const date = new Date(item.assignment.due_at);
    var newdate = date.getFullYear() === 1969 ? "No Date" : (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();

    const assignment = {};
    assignment.key = item.id;
    assignment.name = item.title;
    assignment.points_possible = item.assignment.points_possible;
    assignment.due = newdate;
    assignment.submitted = item.assignment.submission.workflow_state;
    return assignment;
  });
  return list;
}
