export default async function getObservees() {
  const res = await fetch(`/api/observees`);
  const observees = await formatObservees(await res.json());
  return observees;
}

function formatObservees(observees) {
  var list = observees.map(item => {
    const observee = {};
    observee.key = item.id;
    observee.name = item.name;
    return observee;
  });
  return list;
}
