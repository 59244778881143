export default async function getCourses(userid) {
  const res = await fetch(`/api/courses`);
  const courses = await formatCourses(await res.json());
  return courses;
}

function formatCourses(courses) {
  if (!Array.isArray(courses) || courses.length === 0) return null
  var list = courses.map(item => {
    const course = {};
    course.key = item.id;
    course.name = item.name;
    course.enrollmentid = '1096800000000' + item.enrollments[0].associated_user_id;
    course.score = item.enrollments[1].computed_current_score ?? "No Grade";
    return course;
  });
  return list;
}
