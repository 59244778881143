import React from 'react';
import Assignments from './assignments';
import Accordion from 'react-bootstrap/Accordion';

export default function Courses({ courses, id }) {

  const CourseRow = (course, key) => {
    return (
      <Accordion.Item eventKey={key}>
        <Accordion.Header>{course.name} - {course.score}</Accordion.Header>
        <Accordion.Body>
        <Assignments id={course.key} />
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  const courseTable = courses.filter(course => course.enrollmentid === id).map((course, key) => CourseRow(course, key))

  return (
    <>
    <Accordion>
      {courseTable}
    </Accordion>
    </>
  )

}